export default async (query, dijual) => {
  const { data, error } = await useFetch(
    `/api/property?q=${query}&dijual=${dijual}`,
    {
      headers: useRequestHeaders(["cookie"]),
    }
  )

  if (error.value) {
    throw createError({
      ...error.value,
      statusMessage: "unable to fetch api/property",
    })
  }
  return { data }
}
